<template>
	<div class="findPassword">
		<div class="findPassword_box">
			<div class="fpheader">
				<span class="fptit">找回密码</span>
			</div>
			<div class="findPassword_con">
				<el-steps :active="activeVal" finish-status="success" simple>
					<el-step title="填写信息" ></el-step>
					<el-step title="设置新密码" ></el-step>
					<el-step title="找回密码成功" ></el-step>
				</el-steps>
				<el-form v-if="activeVal == 0" :model="basicForm" ref="basicForm" label-width="116px" class="basicForm">
					<el-form-item label="姓名" class="is-required onlyInput" :class="{'is-error':basicRules.stu_name}">
						<el-input v-model="basicForm.stu_name" minlength="2" maxlength="50" @blur="blurName"></el-input>
						<div class="errorText" v-if="basicRules.stu_name"><i class="el-icon-warning"></i>请输入姓名</div>
					</el-form-item>
					<el-form-item label="身份证号" class="is-required onlyInput" :class="{'is-error':basicRules.stu_idcard}">
						<el-input v-model="basicForm.stu_idcard" maxlength="18" @blur="blurIDCard" @input="IDCard"></el-input>
						<div class="errorText" v-if="basicRules.stu_idcard"><i class="el-icon-warning"></i>{{stu_idcard}}</div>
					</el-form-item>
					<el-form-item>
						<el-button class="basicBtn" type="primary" @click="tjbasicForm('basicForm')">下一步</el-button>
					</el-form-item>
				</el-form>
				<el-form v-if="activeVal == 1" :model="setupForm" ref="setupForm" label-width="116px" class="setupForm">
					<div class="el-form--inline phoneItem">
						<el-form-item label="手机号" class="is-required phone" :class="{'is-error':setupRules.stu_phone}">
							<el-input v-model="setupForm.stu_phone" maxlength="11" readonly></el-input>
						</el-form-item>
						<el-form-item label="验证码" class="is-required VCode" :class="{'is-error':setupRules.VCode}">
							<el-input v-model="setupForm.VCode" @input="Phone" maxlength="6" @blur="blurVCode"></el-input>
							<el-button v-if="isSendshow">{{setupForm.times}}秒</el-button>
							<el-button v-else @click.prevent="getVerificationCode">{{setupForm.CodeText}}</el-button>
						</el-form-item>
						<div class="errorText" v-if="setupRules.VCode"><i class="el-icon-warning"></i>{{phoneAndCodeText}}</div>
					</div>
					<el-form-item label="新密码" class="is-required onlyInput" :class="{'is-error':setupRules.stu_password}">
						<el-input v-model="setupForm.stu_password" minlength="6" maxlength="18" show-password @blur="blurPassword" @input="Password"></el-input>
						<div class="errorText" v-if="setupRules.stu_password"><i class="el-icon-warning"></i>{{stu_password}}</div>
					</el-form-item>
					<el-form-item label="确认密码" class="is-required onlyInput" :class="{'is-error':setupRules.Con_password}">
						<el-input v-model="setupForm.Con_password" minlength="6" maxlength="18" show-password @blur="blurConpassword" @input="CPassword"></el-input>
						<div class="errorText" v-if="setupRules.Con_password"><i class="el-icon-warning"></i>{{Con_password}}</div>
					</el-form-item>
					<el-form-item>
						<el-button class="basicBtn" type="primary" @click="tjsetupForm('setupForm')">下一步</el-button>
					</el-form-item>
				</el-form>
				<div v-if="activeVal == 3" class="findSuccess">
					<p>恭喜您，找回密码成功！</p>
					<img src="@/views/images/success_icon.png" />
					<p class="goBack" @click="goBack">返回登录</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				activeVal:0,//选中项
				basicForm:{
					stu_name:"",//姓名
					stu_idcard:"",//身份证号
				},
				basicRules:{
					stu_name:false,//姓名
					stu_idcard:false,//身份证号
				},
				stu_idcard:"",//身份证号错误提示
				setupForm:{
					stu_phone:"",
					VCode:"",//验证码
					CodeText:"获取验证码",//获取验证码
					times:60,//验证码倒计时
					stu_password:"",//密码
					Con_password:"",//确认密码
				},
				setupRules:{
					VCode:false,//验证码
					stu_password:false,//密码
					Con_password:false,//确认密码
				},
				phoneAndCodeText:"",//手机验证码错误提示
				stu_password:"",//密码错误提示
				Con_password:"",//确认密码错误提示
				isSend:false,//是否发送验证码
				isSendshow:false,//是否发送验证码(显示)
			}
		},
		methods: {
			//姓名失去焦点
			blurName(){
				if(this.basicForm.stu_name){
					this.basicRules.stu_name = false
				}else{
					this.basicRules.stu_name = true
				}
			},
			//身份证失去焦点
			blurIDCard(){
				if(this.basicForm.stu_idcard){
					if(this.PublicJs.IdentityCodeValid(this.basicForm.stu_idcard.toUpperCase())){
						this.basicRules.stu_idcard = false;
					}else{
						this.stu_idcard = "请输入正确的身份证号";
						this.basicRules.stu_idcard = true;
					}
				}else{
					this.stu_idcard = "请输入身份证号";
					this.basicRules.stu_idcard = true;
				}
			},
			//身份证号只能输入数字和大写X
			IDCard(){
				this.basicForm.stu_idcard = this.basicForm.stu_idcard.replace(/[^\dXx]/g,"");
			},
			//提交表单
			tjbasicForm(formName){
				//姓名验证
				this.blurName();
				//身份证验证
				this.blurIDCard();
				if(!this.basicRules.stu_name && !this.basicRules.stu_idcard){
					this.$http.post(this.PublicJs.publicPath + "/api/student.index/resetpwdBefore.html", {
						stu_cardid:this.PublicJs.Encrypt(this.basicForm.stu_idcard.toUpperCase()),
						stu_name:this.basicForm.stu_name
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.activeVal = 1;
							this.setupForm.stu_phone = response.data.data.stu_mobile
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						console.log(response)
					});
				}
			},
			//验证码
			blurVCode(){
				if(this.setupForm.VCode){
					if(this.isSend){
						this.setupRules.VCode = false;
					}else{
						this.phoneAndCodeText = "请先获取验证码"
						this.setupRules.VCode = true;
					}
				}else{
					if(this.setupForm.stu_phone){
						this.phoneAndCodeText = "请输入验证码"
						this.setupRules.VCode = true;
					}
				}
			},
			//手机号码只能输入数字
			Phone(){
				this.setupForm.VCode = this.setupForm.VCode.replace(/[^\d]/g,"");
			},
			//点击获取验证码
			getVerificationCode(){
				if(this.setupForm.stu_phone){
					this.$http.post(this.PublicJs.publicPath + "/api/sms/send.html", {
						mobile:this.PublicJs.Encrypt(this.setupForm.stu_phone),
						event:"resetpwd",
						stu_cardid:this.PublicJs.Encrypt(this.basicForm.stu_idcard)
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.isSend = true;
							this.isSendshow = true;
							this.countdown(this);
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						console.log(response)
					});
				}
			},
			//60秒倒计时
			countdown(vm) {
				if (vm.setupForm.times == 0) {
					vm.isSendshow = false;
					vm.setupForm.CodeText = "重新获取";
					vm.setupForm.times = 60;
					return false;
				} else {
					vm.setupForm.times--;
				}
				setTimeout(function() {
					vm.countdown(vm);
				}, 1000);
			},
			//密码验证
			blurPassword(){
				if(this.setupForm.stu_password){
					var pLength = this.setupForm.stu_password.length;
					if(pLength < 6 || pLength > 18){
						this.stu_password = "请输入长度为8-16位的密码"
						this.setupRules.stu_password = true;
					}else{
						this.stu_password = ""
						this.setupRules.stu_password = false;
					}
				}else{
					this.stu_password = "请输入密码"
					this.setupRules.stu_password = true;
				}
			},
			//密码只能输入数字字母
			Password(){
				this.setupForm.stu_password = this.setupForm.stu_password.replace(/[^\da-zA-Z]/g,"");
			},
			//密码只能输入数字字母
			CPassword(){
				this.setupForm.Con_password = this.setupForm.Con_password.replace(/[^\da-zA-Z]/g,"");
			},
			//确认密码验证
			blurConpassword(){
				if(this.setupForm.Con_password){
					if(this.setupForm.stu_password){
						if(this.setupForm.stu_password == this.setupForm.Con_password){
							this.stu_password = ""
							this.setupRules.Con_password = false;
						}else{
							this.Con_password = "两次密码输入不一致"
							this.setupRules.Con_password = true;
						}
					}else{
						//密码验证
						this.blurPassword();
					}
				}else{
					this.Con_password = "请输入确认密码"
					this.setupRules.Con_password = true;
				}
			},
			//下一步
			tjsetupForm(formName){
				//验证码
				this.blurVCode();
				//密码验证
				this.blurPassword();
				//确认密码验证
				this.blurConpassword();
				if(!this.setupRules.VCode && !this.basicRules.stu_password && !this.basicRules.Con_password){
					this.$http.post(this.PublicJs.publicPath + "/api/student.index/resetpwd.html", {
						stu_cardid:this.PublicJs.Encrypt(this.basicForm.stu_idcard),
						stu_mobile:this.PublicJs.Encrypt(this.setupForm.stu_phone),
						code:this.setupForm.VCode,
						password:this.setupForm.stu_password,
					}, {
						emulateJSON: true
					}).then(response => {
						if(response.data.code == 1){
							this.activeVal = 3;
						}else{
							this.$message({
								message: response.data.msg,
								type: 'warning',
								showClose: true
							});
						}
					}, response => {
						console.log(response)
					});
				}
			},
			//返回首页
			goBack(){
				this.$router.push({
					path: "/"
				});
			}
		},
		created(){
			console.log(this.PublicJs.Decrypt("7Je1oqsvAvI69P5wM8dBesYfIRNQlIiCCzAKLyDPaVo="))
			console.log(this.PublicJs.Decrypt("7Je1oqsvAvI69P5wM8dBesYfIRNQlIiCCzAKLyDPaVo="))
		}
	}
</script>

<style scoped="scoped">
	.findPassword {
		padding-top: 15px;
		padding-bottom: 100px;
	}
	.findPassword_box{
		width: 1200px;
		margin: 0 auto;
	}
	.fpheader{
		height: 78px;
		line-height: 78px;
		font-size: 0;
	}
	.fptit{
		font-size: 16px;
		color: #4d6ef2;
		vertical-align: middle;
		margin-right: 18px;
		font-weight: bold;
	}
	.findPassword_con{
		padding-top: 40px;
		width: 100%;
		background-color: #FFF;
		box-shadow: 0px 3px 7px 0px rgba(51, 51, 51, 0.09);
	}
	.el-steps--simple{
		width: 900px;
		margin: 0 auto;
		background-color: #FFF;
	}
	.el-form{
		margin-left: 286px;
		padding: 40px 0;
	}
	.el-input{
		width: 510px;
		margin-right: 15px;
	}
	.errorText{
	    display: inline-block;
	    color: #f56c6c;
	    font-size: 14px;
	    line-height: 40px;
	}
	.basicBtn{
		background: linear-gradient(72deg,#4c6df1 0%, rgba(76,109,241,0.90) 100%);
		border-radius: 4px;
		box-shadow: 0px 2px 7px 0px rgb(77 110 241 / 30%);
		padding: 0;
		width: 120px;
		height: 40px;
		text-align: center;
		line-height: 40px;
		margin-left: 152px;
	}
	.phoneItem .el-form-item__content {
	    margin-left: 0 !important;
	}
	.el-form--inline .el-form-item {
	    display: inline-block;
	    margin-right: 10px;
	    vertical-align: top;
	}
	.phone .el-input{
		width: 202px;
		margin-right: 0;
	}
	.VCode .el-input {
	    width: 100px;
	    margin-right: 15px;
	}
	.VCode .el-button {
	    padding: 0;
	    width: 103px;
	    height: 40px;
	    text-align: center;
	}
	.el-form--inline .el-form-item.phone{
		margin-right: 0;
	}
	.findSuccess{
		text-align: center;
		padding: 40px 0;
	}
	.findSuccess img{
		margin: 40px 0;
	}
	.goBack{
		width: 120px;
		height: 36px;
		background-color: #4D6EF2;
		color: #FFF;
		line-height: 36px;
		border-radius: 3px;
		font-size: 12px;
		margin: 0 auto;
		cursor: pointer;
	}
	.goBack:hover{
		opacity: 0.8;
	}
</style>
<style>
	.phoneItem .el-form-item__content {
	    margin-left: 0 !important;
	}
	.VCode .el-form-item__label {
	    width: 90px !important;
	}
</style>
